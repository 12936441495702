import React from 'react'

function OrderConfiguration() {
  return (
    <div>
      OrderConfiguration
    </div>
  )
}

export default OrderConfiguration
