import { useState } from "react";
import { Link } from "react-router-dom";
import menu from "../../assets/images/menu.svg";
import vrq from "../../assets/images/vrqlogo.png";

type LinkType = {
  name: string;
  path: string;
};

interface AdminSidebarProps {
  links: LinkType[];
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({ links }) => {
  const [selected, setSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="flex flex-col bg-[#1F2937]">
      <div className="flex items-center p-4 bg-[#1F2937] text-white my-6">
        <button
          className="focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={menu} alt="Toggle sidebar" className="w-6 h-6" />
        </button>
        {isOpen && (
          <>
            <img src={vrq} alt="VRQ Tech Logo" className="w-9 h-6" />
            <span className="ml-3 text-lg font-semibold">VRQ TECH</span>
          </>
        )}
      </div>

      {isOpen && (
        <aside className="w-64 bg-[#1F2937] text-[#B9B7BF] p-4 overflow-y-auto custom-scrollbar">
          <nav>
            <ul className="mt-2 space-y-6">
              {links
                .filter((link) => link.name && link.path)
                .map((link, index) => (
                  <li key={link.name}>
                    <Link
                      to={link.path}
                      className={`block py-2 px-3 rounded-md ${
                        selected === index ? "bg-[#374151] text-[#B9B7BF]" : ""
                      }`}
                      onClick={() => setSelected(index)}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
        </aside>
      )}
    </div>
  );
};

export default AdminSidebar;
