import { Route, Routes } from "react-router-dom";
import AdminSidebar from "../../components/common/AdminSidebar";
import Header from "../../components/common/Header";
import AccessPermissions from "./AccessPermissions";
import DashboardOverview from "./DashboardOverview";
import InformationPage from "./InformationPage";
import ManageUsers from "./ManageUsers";
// import Sidebar from "../../components/common/Sidebar";

const AdminDashboard = () => {

  const myLinks = [
    { name: "Dashboard Overview", path: "/admin/dashboard" },
    { name: "Manage Users", path: "manage-users" },
    { name: "Access Permissions", path: "access-permissions" },
    { name: "Onboard Organization", path: "onboard-organization" },
    { name: "View All Organizations", path: "/admin/dashboard" },
  ];

  // const sections = [
  //   {
  //     title: "DELIVERIES",
  //     links: [
  //       { name: "Dashboard Overview", path: "" },
  //       { name: "Manage Users", path: "manage-users" },
  //       { name: "Access Permissions", path: "access-permissions" },
  //       { name: "Onboard Organization", path: "onboard-organization" },
  //       { name: "View All Organizations", path: "" },
  //     ],
  //   },
  // ];
  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <AdminSidebar links={myLinks} />
        {/* <Sidebar navigationSections={sections} /> */}
        <main className="flex-1 bg-gray-100 overflow-auto custom-scrollbar">
          <Routes>
            <Route path="/" element={<DashboardOverview />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/access-permissions" element={<AccessPermissions />} />
            <Route path="/onboard-organization" element={<InformationPage />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
