import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import Logo from "../../components/common/Logo";
import Spinner from "../../components/common/Spinner";
import { setToken } from "../../redux/authSlice";

const ForgotPasswordModal: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "http://35.182.15.154:8000/oauth2/token",
        {
          grant_type: "password",
          username: "sakshi.vij",
          password: currentPassword,
          scope: "openapi profiles",
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "http://localhost:3000"
          },
        }
      );
      dispatch(setToken(response.data));
      console.log("Token is in redux")
      handleNavigate();
    } catch (error) {
      console.error("Error fetching token:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate("/admin/dashboard");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#111827]">
      {loading && <Spinner message="Generating Token..." />}
      <div className="bg-[#1F2937] p-12 rounded-lg shadow-lg text-center">
        <div className="mb-8">
          <Logo className="w-24 h-24 mx-auto" imagePath={img} />
        </div>
        <h1 className="text-2xl font-bold text-[#B9B7BF] mb-4">
          Reset Password
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-[#B9B7BF] mb-2">
              Current Password
            </label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF]"
            />
          </div>
          <div className="mb-4">
            <label className="block text-[#B9B7BF] mb-2">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF]"
            />
          </div>
          <div className="mb-8">
            <label className="block text-[#B9B7BF] mb-2">
              Confirm New Password
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-[#111827] text-[#B9B7BF]"
            />
          </div>
          <button
            type="submit"
            className="bg-[#3485E2] text-white hover:bg-blue-700 p-2 rounded-lg"
            style={{ width: "350px" }}
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
