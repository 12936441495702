import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import LanguageToggle from "../../components/common/LanguageChanger";
import Logo from "../../components/common/Logo";
import { register } from "../../services/auth";

const Registration = () => {
  const [t, i18n] = useTranslation("global");
  const [phone, setPhone] = useState("");
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [org_name, setOrganizationName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const result = await register(email, phone, org_name, full_name, password);

    if (result.success) {
      navigate("/");
    } else {
      alert(result.message);
      console.log(result.message);
    }
  };

  return (
    <div className="flex min-h-screen bg-[#111827]">
      <LanguageToggle />
      <div className="w-2/5 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-blue-300">
            {t("registration.welcome")}
          </h1>
          <div className="mt-4 flex justify-center">
            <Logo imagePath={img} />
          </div>
        </div>
      </div>
      <div className="w-3/5 flex items-center justify-center p-8 bg-[#1f2937]">
        <div className="w-full max-w-md">
          <h2 className="text-2xl font-semibold text-blue-300 text-center">
            {t("registration.title")}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                className="block text-sm text-[#9ca3af]"
                htmlFor="full_name"
              >
                {t("registration.full_name")}
              </label>
              <input
                className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
                type="text"
                id="full_name"
                placeholder={t("registration.full_name_info")}
                onChange={(e) => setFullName(e.target.value)}
                value={full_name}
              />
            </div>
            <div>
              <label className="block text-sm text-[#9ca3af]" htmlFor="email">
                {t("registration.email")}
              </label>
              <input
                className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
                type="email"
                id="email"
                placeholder={t("registration.email_info")}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div>
              <label className="block text-sm text-[#9ca3af]" htmlFor="phone">
                {t("registration.phone_number")}
              </label>
              <input
                className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
                type="text"
                id="phone"
                placeholder={t("registration.phone_number_info")}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label
                className="block text-sm text-[#9ca3af]"
                htmlFor="organization"
              >
                {t("registration.organization_name")}
              </label>
              <input
                className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
                type="text"
                id="organization"
                placeholder={t("registration.organization_name_info")}
                onChange={(e) => setOrganizationName(e.target.value)}
                value={org_name}
              />
            </div>
            <div>
              <label
                className="block text-sm text-[#9ca3af]"
                htmlFor="password"
              >
                {t("registration.password")}
              </label>
              <input
                className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
                type="password"
                id="password"
                placeholder={t("registration.password_info")}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div>
              <label
                className="block text-sm text-[#9ca3af]"
                htmlFor="confirm-password"
              >
                {t("registration.confirm_password")}
              </label>
              <input
                className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
                type="password"
                id="confirm-password"
                placeholder={t("registration.confirm_password")}
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
            </div>
            <div className="flex justify-center mb-4">
              <button
                className="w-full bg-[#3485E2] text-white hover:bg-blue-700 rounded-md p-2"
                style={{ width: "248px", height: "42.514px" }}
              >
                {t("registration.sign_up")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Registration;
