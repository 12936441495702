import React from 'react'
import Header from '../../components/common/Header'
import AdminSidebar from '../../components/common/AdminSidebar';

function AdminFlow() {

  const myLinks = [
    { name: "Home", path: "" },
  ];
  
  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <AdminSidebar links={myLinks} />
      </div>
    </div>
  );
}

export default AdminFlow