import React from "react";

interface SpinnerProps {
  message?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ message = "Loading..." }) => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-[rgba(255,255,255,0.8)] z-50">
      <div className="flex flex-col items-center">
        <div
          className="relative"
          style={{ height: "64px", width: "64px" }}
        >
          <div
            className="absolute inset-0 border-8 border-transparent rounded-full border-t-[var(--secondary,#1F2937)] animate-spin"
            style={{
              borderTopWidth: "8px",
              borderRightWidth: "8px",
              borderBottomWidth: "8px",
              borderLeftWidth: "8px",
              borderTopColor: "var(--secondary, #1F2937)",
            }}
          ></div>
        </div>
        <span className="mt-4 text-[var(--secondary,#1F2937)] text-2xl font-bold">
          {message}
        </span>
      </div>
    </div>
  );
};

export default Spinner;
