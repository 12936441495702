import { User } from '../../models/user';
import apiClient from '../common/apiClient';
import { ApiService } from '../common/apiService';

class UserService implements ApiService<User> {
  private readonly endpoint: string = '/users';

  async getAll(): Promise<User[]> {
    const response = await apiClient.get<User[]>(this.endpoint);
    return response.data;
  }

  async getById(id: string): Promise<User> {
    const response = await apiClient.get<User>(`${this.endpoint}/${id}`);
    return response.data;
  }

  async create(data: User): Promise<User> {
    const response = await apiClient.post<User>(this.endpoint, data);
    return response.data;
  }

  async update(id: string, data: User): Promise<User> {
    const response = await apiClient.put<User>(`${this.endpoint}/${id}`, data);
    return response.data;
  }

  async partialUpdate(id: string, data: Partial<User>): Promise<User> {
    const response = await apiClient.patch<User>(`${this.endpoint}/${id}`, data);
    return response.data;
  }

  async delete(id: string): Promise<void> {
    await apiClient.delete(`${this.endpoint}/${id}`);
  }
}

export default new UserService();
