import { useEffect } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useSelector } from "react-redux"; // Import useSelector
import group from "../../assets/images/group.svg";
import setting from "../../assets/images/settings_admin.svg";
import userService from "../../services/admin/userService";

const stats = [
  { title: "Total Users", value: 50 },
  { title: "Active Users", value: 45 },
  { title: "Pending Invitations", value: 5 },
];

const activities = [
  {
    icon: group,
    text: "User John Doe invited",
    subtext: "john.doe@example.com, July 23 2024, 4:20 PM",
  },
  {
    icon: setting,
    text: "New role assigned to John Doe",
    subtext: "john.doe@example.com, July 23 2024, 9:16 PM",
  },
];

const DashboardOverview = () => {
  const token = useSelector((state: any) => state.auth.token);

  useEffect(() => {
    console.log(token);
    const fetchUsers = async () => {
      try {
        const response = await userService.getAll();
        console.log("Users Data:", response);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    };

    if (token) {
      fetchUsers();
    }
  }, [token]); 

  return (
    <div className="bg-[#111827] p-6 text-[#B9B7BF] min-h-screen overflow-hidden">
      <h1 className="text-2xl font-bold text-[#B9B7BF] mb-20">Dashboard</h1>
      <div className="flex justify-center mb-20">
        <div className="flex flex-wrap justify-between w-full max-w-4xl space-y-4 md:space-y-0">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-[#1F2937] p-4 rounded-lg border border-[#1F2937] text-center h-32 w-full md:w-40 flex flex-col justify-center items-center"
            >
              <h2 className="text-xl font-semibold text-[#B9B7BF]">
                {stat.value}
              </h2>
              <p className="text-xs text-[#9CA3AF]">{stat.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-[#1F2937] p-4 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-4 border-b border-[#464A55] pb-2">
          <h2 className="text-lg font-semibold text-[#B9B7BF]">
            Activity Feed
          </h2>
          <FiMoreHorizontal className="text-[#B9B7BF] text-xl" />
        </div>
        <div className="mt-4 mb-2">
          {activities.map((activity, index) => (
            <div
              key={index}
              className={`py-2 ${
                index !== activities.length - 1
                  ? "border-b border-[#464A55] mt-2 mb-2"
                  : ""
              }`}
            >
              <div className="flex items-center">
                <img
                  src={activity.icon}
                  alt="Activity icon"
                  className="w-6 h-6 mr-6"
                />
                <div className="flex flex-col">
                  <span className="text-[#9CA3AF]">{activity.text}</span>
                  <span className="text-sm text-[#9CA3AF]">
                    {activity.subtext}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
