/*
const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await UserService.getAll();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

*/

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import img from '../../assets/images/logistics2.png';
import LanguageToggle from "../../components/common/LanguageChanger";
import Logo from "../../components/common/Logo";
import { login } from "../../services/auth";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result = await login(username, password);

    if (result.success) {
      navigate("/dispatchers");
    } else {
      alert(result.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#111827]">
      <LanguageToggle />
      <div className="p-8 rounded-lg" style={{ width: "565px" }}>
        <div className="flex justify-center mb-6">
          <Logo className="w-max h-12" imagePath={img}/>
        </div>
        <h2 className="text-2xl font-semibold text-center mb-6 text-blue-300">
          {t("login.title")}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="block text-[#374151] mb-1">
              {t("login.username")}
            </label>
            <input
              type="text"
              id="username"
              placeholder={t("login.username_info")}
              className="w-full p-2 border border-[#374151] rounded-lg bg-[#111827] text-[#374151]"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-[#374151] mb-1">
              {t("login.password")}
            </label>
            <input
              type="password"
              id="password"
              placeholder={t("login.password_info")}
              className="w-full p-2 border border-[#374151] rounded-lg bg-[#111827] text-[#374151]"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="remember"
                className="mr-2"
                style={{
                  width: "16px",
                  height: "16px",
                  accentColor: "#3485E2",
                }}
              />
              <label htmlFor="remember" className="text-[#5D5E68]">
                {t("login.remember_me")}
              </label>
            </div>
            <a href="/" className="text-[#374151]">
              {t("login.forgot_password")}
            </a>
          </div>
          <div className="flex justify-center mb-4 mt-6">
            <button
              type="submit"
              className="bg-[#3485E2] text-white hover:bg-blue-700 p-2 rounded-lg"
              style={{ width: "350px" }}
            >
              {t("login.sign_in")}
            </button>
          </div>
        </form>
        {/* <div className="flex items-center justify-center mt-6">
          <div className="w-2/4 border-t border-gray-500"></div>
          <span className="text-gray-500 mx-2">{t("login.or")}</span>
          <div className="w-2/4 border-t border-gray-500"></div>
        </div>
        <div className="flex justify-center mt-4">
          <Link to="/register">
            <button
              className="font-semibold w-full bg-[#111827] text-[#3485E2] p-2 rounded-lg border-2 border-[#3485E2]"
              style={{ width: "350px" }}
            >
              {t("login.account")}
            </button>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
