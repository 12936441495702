// pages/DispatchersPage.js
import { Route, Routes } from "react-router-dom";
import Sidebar from "../../components/common/Sidebar";
import CustomerSupport from "./SidebarPages/CustomerSupport";
import DashBoard from "./SidebarPages/DashBoard";
import Drivers from "./SidebarPages/Drivers";
import History from "./SidebarPages/History";
import Issues from "./SidebarPages/Issues";
import Monitoring from "./SidebarPages/Monitoring";
import OrderConfiguration from "./SidebarPages/OrderConfiguration";
import Orders from "./SidebarPages/Orders";
import OrdersInventory from "./SidebarPages/OrdersInventory";
import OrdersManagement from "./SidebarPages/OrdersManagement";
import RouteOptimization from "./SidebarPages/RouteOptimization";
import Schedulers from "./SidebarPages/Schedulers";
import Settings from "./SidebarPages/Settings";

const DispatchersPage = () => {

  const sections = [
    {
      title: "DELIVERIES",
      links: [
        { name: "Dashboard", path: "" },
        { name: "Order Configuration", path: "order-configuration" },
        { name: "Monitoring", path: "monitoring" },
      ],
    },
    {
      title: "OPERATIONS",
      links: [
        { name: "Orders", path: "orders" },
        { name: "Orders Inventory", path: "orders-inventory" },
        { name: "Orders Management", path: "orders-management" },
        { name: "Schedulers", path: "schedulers" },
        { name: "Route Optimization", path: "route-optimization" },
        { name: "History", path: "history" },
      ],
    },
    {
      title: "RESOURCES",
      links: [
        { name: "Issues", path: "issues" },
        { name: "Drivers", path: "drivers" },
        { name: "Customer Support", path: "customer-support" },
        { name: "Settings", path: "settings" },
      ],
    },
  ];
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar navigationSections={sections} />
      <main className="flex-1 p-6 bg-gray-100 overflow-y-auto">
        <Routes>
          <Route path="/" element={<DashBoard />} />
          <Route path="/order-configuration" element={<OrderConfiguration />} />
          <Route path="/monitoring" element={<Monitoring />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders-inventory" element={<OrdersInventory />} />
          <Route path="/orders-management" element={<OrdersManagement />} />
          <Route path="/schedulers" element={<Schedulers />} />
          <Route path="/route-optimization" element={<RouteOptimization />} />
          <Route path="/history" element={<History />} />
          <Route path="/issues" element={<Issues />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </main>
    </div>
  );
};

export default DispatchersPage;
