import { Route, Routes } from "react-router-dom";
import DispatchersPage from "../pages/dispatcher/DispatchersPage";
import ForgotPasswordModal from "../pages/dispatcher/ForgotPasswordModal";
import Login from "../pages/dispatcher/Login";
import Registration from "../pages/dispatcher/Registration";

function DispatcherRoutes() {
  return (
    <Routes>
        <Route path="" element={<Login />} />
        <Route path="register" element={<Registration />} />
        <Route path="password-reset" element={<ForgotPasswordModal />} />
        <Route path="dispatchers/*" element={<DispatchersPage />} />
    </Routes>
  );
}

export default DispatcherRoutes;
