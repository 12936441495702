import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/common/Logo";
import img from "../../assets/images/logistics.svg";
import Spinner from "../../components/common/Spinner";

function AdminLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleContinue = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate("dashboard");
    }, 2000);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#111827]">
      <div className="mt-2">
      </div>
      <div
        className="bg-[#1F2937] p-8 shadow-lg rounded-lg mt-4 flex flex-col justify-between mb-6"
        style={{ width: "600px", height: "720px" }}
      >
        <div>
          <h1 className="text-2xl font-semibold text-center text-blue-300 mb-4">
            Welcome to LogisticsLab
          </h1>
          <div className="flex justify-center mb-6">
            <Logo imagePath={img}/>
          </div>
          <h2 className="text-lg font-medium text-center text-blue-300 mb-6">
            Admin Login
          </h2>
          <form className="mt-6" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="login-id"
                className="block text-sm text-[#B9B7BF]"
              >
                Login ID
              </label>
              <input
                type="text"
                id="login-id"
                className="mt-1 block w-full p-2 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF]"
                placeholder="root"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm text-[#B9B7BF]"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="mt-1 block w-full p-2 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF]"
                placeholder="********"
                required
              />
            </div>
          </form>
        </div>
        <div className="flex justify-center mt-12">
          {isLoading ? (
            <Spinner message="Validating your credentials" />
          ) : (
            <button
              onClick={handleContinue}
              className="bg-[#3485E2] text-white hover:bg-blue-700 p-2 rounded-lg"
              style={{ width: "350px" }}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
