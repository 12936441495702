import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import bell from "../../assets/images/bell.svg";
import account from "../../assets/images/Ellipse 2.svg";
import img from "../../assets/images/logistics2.png";
import settings from "../../assets/images/settings.svg";
import Logo from "./Logo";
import { clearToken } from "../../redux/authSlice";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(clearToken()); 
    navigate("/admin");
    console.log("Logout clicked");
  };

  return (
    <header className="bg-[#0D1423] text-white flex justify-between items-center p-2 w-full">
      <Logo className="w-30 h-8" imagePath={img} />
      <div className="flex items-center space-x-10 mr-6 relative">
        <img src={bell} alt="Bell Icon" className="h-6 w-6 text-[#5D5E68]" />
        <img src={settings} alt="Settings Icon" className="h-6 w-6" />
        <div className="relative">
          <img
            src={account}
            alt="Account Icon"
            className="h-6 w-6 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg">
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
