import React from "react";


interface LogoProps {
  className?: string;
  imagePath?: string;
}

const Logo: React.FC<LogoProps> = ({ className, imagePath }) => {
  return <img src={imagePath} alt="logo" className={className} />;
};

export default Logo;
