// TODO: Remove this in future

import apiRequest from "./utils";

const API_BASE_URL = process.env.BASE_URL;

// export const login = async (email, password) => {
//   return apiRequest(`${API_BASE_URL}/login`, "POST", { email, password });
// };


export const login = async (username, password) => {
  try {
    const response = await fetch("https://dummyjson.com/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        password,
        expiresInMins: 30,
      }),
    });

    const responseData = await response.json();

    if (response.ok) {
      return { success: true, data: responseData };
    } else {
      return {
        success: false,
        message: responseData.message || "An error occurred",
      };
    }
  } catch (error) {
    console.error("API request error:", error);
    return { success: false, message: "An error occurred. Please try again." };
  }
};


export const register = async (
  email,
  phone_number,
  org_name,
  username,
  full_name,
  password
) => {
  return apiRequest(`${API_BASE_URL}/register`, "POST", {
    email,
    phone_number,
    org_name,
    username,
    full_name,
    password,
  });
};
