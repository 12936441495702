import React from "react";
import { useTranslation } from "react-i18next";

export default function LanguageToggle() {
  const [t, i18n] = useTranslation("global");

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="absolute top-4 right-4 flex items-center space-x-2">
      <select
        id="languageSelect"
        value={i18n.language}
        onChange={changeLanguage}
        className="bg-gray-700 text-white border border-gray-600 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        <option value="en">English</option>
        <option value="fr">Français</option>
        <option value="zh">中文</option>
        <option value="ru">Русский</option>
      </select>
    </div>
  );
}
