import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./assets/styles/styles.css";
import AdminRoutes from "./routes/AdminRoutes";
import DispatcherRoutes from "./routes/DispatcherRoutes";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<DispatcherRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
      </Routes>
    </Router>
  );
}
