import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import store from './redux/store';
import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_zh from "./translations/zh/global.json";
import global_ru from "./translations/ru/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";


i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr,
    },
    zh: {
      global: global_zh,
    },
    ru: {
      global: global_ru,
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
