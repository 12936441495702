const apiRequest = async (url, method, data) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (response.ok) {
      return { success: true, data: responseData };
    } else {
      return {
        success: false,
        message: responseData.message || "An error occurred",
      };
    }
  } catch (error) {
    console.error("API request error:", error);
    return { success: false, message: "An error occurred. Please try again." };
  }
};

export default apiRequest;
