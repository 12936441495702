import { useNavigate } from "react-router-dom";
import img from "../../assets/images/logistics.svg";
import Logo from "../../components/common/Logo";

function InformationPage() {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/admin/dashboard");
  };


  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#111827]">
      <div className="mt-2">
      </div>
      <div
        className="bg-[#1F2937] p-8 rounded-lg shadow-lg"
        style={{ width: "950px" }}
      >
        <div className="flex flex-col items-center mb-8">
          <Logo className="w-48 h-48 mx-auto" imagePath={img}/>
          <h2 className="text-2xl font-bold text-blue-300 mt-2">
            Setup Organization
          </h2>
        </div>
        <form className="space-y-4">
          <div>
            <label className="block text-sm text-[#9ca3af]" htmlFor="org-name">
              Organization Name
            </label>
            <input
              type="text"
              id="org-name"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              placeholder="VRQ Tech"
            />
          </div>
          <div>
            <label className="block text-sm text-[#9ca3af]" htmlFor="site-name">
              Site Name
            </label>
            <input
              type="text"
              id="site-name"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              placeholder="vrqtech.logisticslab.ca"
            />
          </div>
          <div>
            <label
              className="block text-sm text-[#9ca3af]"
              htmlFor="admin-name"
            >
              Admin Name
            </label>
            <input
              type="text"
              id="admin-name"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              placeholder="Somya Srivastava"
            />
          </div>
          <div>
            <label
              className="block text-sm text-[#9ca3af]"
              htmlFor="admin-email"
            >
              Admin Email Address
            </label>
            <input
              type="email"
              id="admin-email"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              placeholder="somya.vrq@gmail.com"
            />
          </div>
          <div>
            <label
              className="block text-sm text-[#9ca3af]"
              htmlFor="admin-phone"
            >
              Admin Phone Number
            </label>
            <input
              type="tel"
              id="admin-phone"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              placeholder="987654321"
            />
          </div>
          <div>
            <label
              className="block text-sm text-[#9ca3af]"
              htmlFor="additional-details"
            >
              Additional Details
            </label>
            <textarea
              id="additional-details"
              className="mt-1 block w-full border border-[#374151] rounded-md p-2 bg-[#111827] text-[#374151]"
              placeholder="VRQ Tech is a Fleetbase Firm operating since 2002, having a headcount of 20 drivers and 22 trucks."
            ></textarea>
          </div>
          <div className="flex justify-center mb-4">
            <button
              type="submit"
              className="bg-[#3485E2] text-white hover:bg-blue-700 rounded-md p-2"
              style={{ width: "248px", height: "42.514px" }}
              onClick={handleContinue}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InformationPage;
