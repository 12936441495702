import { FaSearch } from "react-icons/fa";
import trash from "../../assets/images/trash.svg";

function ManageUsers() {
  return (
    <div className="bg-[#111827] p-6 text-[#B9B7BF] min-h-screen overflow-hidden">
      <h1 className="text-2xl font-bold text-[#B9B7BF] mb-10">Invite Users</h1>
      <div className="flex mb-5">
        <div className="flex w-full max-w-6xl space-x-20">
          <input
            type="text"
            id="users-emails"
            className="mt-1 block w-1/2 p-2 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF]"
            placeholder="Search by email"
            required
          />
          <select
            id="languageSelect"
            className="bg-[#111827] text-[#B9B7BF] border border-[#374151] rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-300 w-1/3"
          >
            <option value="dispatcher">Dispatcher</option>
            <option value="driver">Driver</option>
            <option value="customer">Customer</option>
          </select>
          <button className="bg-blue-500 text-white border border-blue-700 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-300 w-1/6">
            Invite Users
          </button>
        </div>
      </div>
      <div className="mt-20 mb-10">
        <h1 className="text-2xl font-bold text-[#B9B7BF]">Manage Users</h1>
      </div>
      <div className="relative w-1/2 mb-10">
        <input
          type="text"
          id="search-users"
          className="mt-1 block w-full p-2 border border-[#374151] rounded-md bg-[#111827] text-[#B9B7BF] pr-10"
          placeholder="Search by username,email"
          required
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <FaSearch className="h-5 w-5 text-[#B9B7BF]" />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-[#1F2937] border border-[#374151]">
          <thead>
            <tr className="bg-[#374151] text-[#B9B7BF]">
              <th className="p-4 text-left">
                <input
                  type="checkbox"
                  aria-label="Select all"
                  className="form-checkbox text-blue-500 bg-[#1F2937] border-[#374151] rounded focus:ring-blue-300"
                />
              </th>
              <th className="p-4 text-left">Email Address</th>
              <th className="p-4 text-left">Username</th>
              <th className="p-4 text-left">Role</th>
              <th className="p-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr key={index} className="border-b border-[#374151]">
                <td className="p-4">
                  <input
                    type="checkbox"
                    aria-label="Select user"
                    className="form-checkbox text-blue-500 bg-[#1F2937] border-[#374151] rounded focus:ring-blue-300"
                  />
                </td>
                <td className="p-4">johndoe@yrtech.net</td>
                <td className="p-4">John Doe</td>
                <td className="p-4">
                  <select className="bg-[#1F2937] text-[#B9B7BF] border border-[#374151] rounded p-1">
                    <option>Dispatcher</option>
                    <option>Driver</option>
                    <option>Customer</option>
                  </select>
                </td>
                <td className="p-4 flex items-center">
                  <button className="ml-2 text-red-500 border border-[#3485E2] rounded">
                    <img
                      src={trash}
                      className="h-7 w-7 py-1 mx-2 my-1"
                      alt="Delete icon"
                      aria-hidden="true"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageUsers;
