import { Link } from "react-router-dom";
import img from "../../assets/images/logistics2.png";
import Logo from "./Logo";

type LinkType = {
  name: string;
  path: string;
};

type NavigationSection = {
  title: string;
  links: LinkType[];
};

interface SidebarProps {
  navigationSections: NavigationSection[];
}

const Sidebar: React.FC<SidebarProps> = ({ navigationSections }) => {
  return (
    <aside className="w-64 bg-[#1F2937] text-white p-4 overflow-y-auto custom-scrollbar">
      <div className="flex items-center mb-6">
        <Logo imagePath={img} />
      </div>
      <nav>
        {navigationSections.map((section) => (
          <div key={section.title} className="mt-4">
            <h2 className="text-sm font-semibold text-gray-400 mb-4">
              {section.title}
            </h2>
            <ul className="mt-2">
              {section.links.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.path}
                    className="block py-2 px-3 rounded-md hover:bg-[#374151] hover:text-white transition-colors duration-300"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
