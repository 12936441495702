import { Route, Routes } from "react-router-dom";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminLogin from "../pages/admin/AdminLogin";
import InformationPage from "../pages/admin/InformationPage";
import AdminFlow from "../pages/admin/AdminFlow";

function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AdminLogin />} />
      <Route path="/welcome" element={<AdminFlow />} />
      <Route path="/information" element={<InformationPage />} />
      <Route path="/dashboard/*" element={<AdminDashboard />} />
    </Routes>
  );
}

export default AdminRoutes;













