import React from "react";

const permissions = [
  { text: "View All Orders" },
  { text: "View Past Orders" },
  { text: "View All routes" },
  { text: "View Past Deliveries" },
  { text: "View All Reports" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
  { text: "View All routes" },
];

function AccessPermissions() {
  return (
    <div className="bg-[#111827] p-6 text-[#B9B7BF] min-h-screen overflow-hidden">
      <h1 className="text-2xl text-[#B9B7BF] mb-10">
        Edit User Access Permissions
      </h1>
      <div className="flex flex-col w-full mb-20">
        <h2 className="text-1xl text-[#B9B7BF] mb-5">Select role</h2>
        <select
          id="roleSelect"
          className="bg-[#111827] text-[#B9B7BF] border border-[#374151] rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-300 w-1/3"
        >
          <option value="dispatcher">Dispatcher</option>
          <option value="admin">Admin</option>
        </select>
      </div>

      <h2 className="text-2xl text-[#B9B7BF] mb-5">Permissions</h2>
      <div className="bg-[#1F2937] p-16 rounded-lg shadow-md mb-5">
        <div className="mt-4 mb-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          {permissions.map((permission, index) => (
            <div className="flex items-center" key={index}>
              <input
                type="checkbox"
                id={`checkbox-${index}`}
                className="h-4 w-4 text-blue-500 bg-[#1F2937] border-[#374151] rounded focus:ring-blue-300"
              />
              <label
                htmlFor={`checkbox-${index}`}
                className="ml-2 text-[#B9B7BF]"
              >
                {permission.text}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button className="bg-blue-500 text-white border border-blue-700 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-300 w-1/6">
          Save Permissions
        </button>
      </div>
    </div>
  );
}

export default AccessPermissions;
